<template>
    <vx-card :title="'Putaway Unloading Items'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back
            </vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Item Request Document Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="reqCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Handover Document Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvas Unloading Document Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="unlCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory ? selectedTerritory.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse ? selectedWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales ? selectedSales.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="loadingDate" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="unloadingDate" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h4>Requested Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="35%">SKU Description</th>
                            <th width="10%">Qty</th>
                            <th width="15%">Unit</th>
                            <th width="10%">Qty UOM</th>
                            <th width="10%">Total Qty (UOM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in requestedLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="(skuLine.qty * skuLine.qty_uom)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h4>Handover Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="35%">SKU Description</th>
                            <th width="7%">Qty</th>
                            <th width="11%">Unit</th>
                            <th width="7%">Qty UOM</th>
                            <th width="10%">Total Qty (UOM)</th>
                            <th width="20%">Batch / Exp. Date</th>
                            <th width="5%">Warehouse Handover</th>
                            <th width="5%">Sales Handover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in handoverLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="(skuLine.qty * skuLine.qty_uom)" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.batch" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-checkbox v-model="skuLine.warehouse_handover" disabled></vs-checkbox>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-checkbox v-model="skuLine.sales_handover" disabled></vs-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h4>Putaway Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="29%">SKU Data</th>
                            <th width="5%">Qty</th>
                            <th width="7%">HU</th>
                            <th width="5%">Qty UOM</th>
                            <th width="10%">Total Qty (UOM)</th>
                            <th width="7%">Status</th>
                            <th width="17%">Batch / Exp. Date</th>
                            <th width="20%">Storage Area</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(tr, index) in skuLines">
                            <tr style="padding-bottom: 5px" :key="index">
                                <td class="td vs-table--td align-top" style="padding: 5px" :rowspan="tr.putaway_lines.length">
                                    <vs-input :value="tr.label" :readonly="true" class="w-full"></vs-input>
                                </td>

                                <!-- first row -->
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="tr.putaway_lines[0].qty" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="tr.putaway_lines[0].unit" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="tr.putaway_lines[0].qty_uom" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="(tr.putaway_lines[0].qty * tr.putaway_lines[0].qty_uom)" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="tr.putaway_lines[0].status" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="tr.putaway_lines[0].batch" :readonly="true" class="w-full"></vs-input>
                                </td>
                                <td class="td vs-table--td align-top" style="padding: 5px">
                                    <vs-input :value="tr.putaway_lines[0].warehouse_area_code" :readonly="true" class="w-full"></vs-input>
                                </td>
                            </tr>

                            <!-- putaway lines -->
                            <template v-for="(putaways, idx) in skuLines[index].putaway_lines">
                                <tr style="padding-bottom: 5px;" :key="index + '-' + idx" v-if="idx > 0">
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.qty" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.unit" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.qty_uom" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="(putaways.qty * putaways.qty_uom)" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.status" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.batch" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                    <td class="td vs-table--td align-top" style="padding: 5px">
                                        <vs-input :value="putaways.warehouse_area_code" :readonly="true" class="w-full"></vs-input>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            reqCode: null,
            handoverCode: null,
            unlCode: null,
            status: null,
            selectedTerritory: null,
            selectedWarehouse: null,
            selectedSales: null,
            loadingDate: null,
            unloadingDate: null,
            requestedLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    qty: 0,
                    unit: null,
                    qty_uom: 0,
                }
            ],
            handoverLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    qty: 0,
                    unit: null,
                    qty_uom: 0,
                    batch: null,
                    warehouse_handover: false,
                    sales_handover: false,
                }
            ],
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    putaway_lines: [
                        {
                            putaway_id: 0,
                            status: null,
                            qty: 0,
                            unit: null,
                            qty_uom: 0,
                            batch: null,
                            warehouse_area_code: null,
                        }
                    ]
                }
            ]
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/v1/canvas/putaway-data/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.reqCode = resp.data.request_doc
                    this.handoverCode = resp.data.handover_doc
                    this.unlCode = resp.data.unloading_doc
                    this.status = resp.data.status
                    this.selectedTerritory = resp.data.territory
                    this.selectedWarehouse = resp.data.warehouse
                    this.selectedSales = resp.data.sales
                    this.loadingDate = resp.data.loading_date
                    this.unloadingDate = resp.data.unloading_date
                    this.requestedLines = resp.data.item_request_lines
                    this.handoverLines = resp.data.handover_lines
                    this.skuLines = resp.data.sku_lines
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log("error => ", error);
            })
        },
        handleBack() {
            let tabOrder = 0
            if (this.status == "1") {
                tabOrder = 8
            } else if (this.status == "2") {
                tabOrder = 9
            }

            this.$router.push({
                name: "canvas.item-request",
                params: {status: tabOrder,}
            });
        },
    },
    watch: {},
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>